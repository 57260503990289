import React, { useEffect, useState } from 'react'
import { Card, CardHeader, Divider, Grid, Typography, } from '@mui/material'
import Chart from 'react-apexcharts'
import { useTheme } from '@mui/material/styles';
import Box from '@mui/system/Box';
import { useDispatch, useSelector } from 'react-redux';
import { getChangeRequestSummaryByStatus } from '../../store/slices/dashboard';
import { colors } from './PositionMappedGraph';
import { toTitleCase } from '../../utils/string';

const series = [
  {name: 'Active'
  },
  {name: 'Approved'
  },
  {name: 'Rejected'
  },
  {name: 'Cancelled'
  }
];

export const ChangeRequestsGraph = (props) => {
  const theme = useTheme();
  const [apiChartSeries, setAPIChartSeries] = useState(series)
  const { changeRequestSummaryByStatus }
    = useSelector((state) => state.dashboard);

    useEffect(() => {
    const { status, message, changeRequestCount, ...rest } = changeRequestSummaryByStatus?.data?.statusWiseStats || {}
    const chartStats = rest
    if (chartStats) {
      const newSeries = Object.keys(chartStats).map((key, index) => {
        return {
          color: colors[index],
          data: chartStats[key],
          name: key
        }
      })
      setAPIChartSeries(newSeries)
    }
  }, [changeRequestSummaryByStatus])

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getChangeRequestSummaryByStatus())
  }, [])

  const chartOptions = {
    chart: {
      background: 'transparent',
      stacked: false,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    colors: apiChartSeries.map((item) => item.color),
    dataLabels: {
      enabled: false
    },
    grid: {
      padding: {
        left: 0,
        right: 0
      }
    },
    labels: apiChartSeries.map((item) => item.name),
    legend: {
      show: false
    },
    stroke: {
      width: 0
    },
    theme: {
      mode: theme.palette.mode
    },
    tooltip: {
      theme: theme.palette.mode
    }
  };

  const chartSeries = apiChartSeries.map((item) => item.data);

  return (
    <Card
      sx={{ height: '100%' }}
      {...props}>
      <CardHeader title="Change Requests" />
      <Divider />
      <Grid
        alignItems="center"
        container
        sx={{
          justifyContent: {
            sm: 'flex-start',
            xs: 'center'
          },
          py: 3
        }}
      >
        <Grid item>
          <Chart
            options={chartOptions}
            series={chartSeries}
            type="donut"
            height={170}
            legend={false}
          />
        </Grid>
        <Grid item>
          <Typography
            color="textSecondary"
            variant="subtitle2"
          >
            Total Change Requests
          </Typography>
          <Typography
            color="textPrimary"
            sx={{ my: 1 }}
            variant="h4"
          >
            {changeRequestSummaryByStatus?.data?.changeRequestCount}
          </Typography>
          {apiChartSeries.map((item) => (
            <Box
              key={item.name}
              sx={{
                alignItems: 'center',
                display: 'flex',
                '& + &': {
                  mt: 1
                }
              }}
            >
              <Box
                sx={{
                  backgroundColor: item.color,
                  borderRadius: 1,
                  height: 8,
                  mr: 1,
                  width: 8
                }}
              />
              <Typography
                color="textPrimary"
                variant="body2"
              >
                {`${toTitleCase(item.name)} : ${item.data}`}
              </Typography>
            </Box>
          ))}
        </Grid>
      </Grid>
    </Card>
  );
};
