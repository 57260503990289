import React, { useEffect, useState } from 'react'
import { Card, CardContent, CardHeader, Divider } from '@mui/material'
import Chart from 'react-apexcharts'
import { useTheme } from '@mui/material/styles';
import { ActionsMenu } from '../../components/ActionsMenu'
import { useDispatch, useSelector } from 'react-redux';
import { getPositionClosureSummary } from '../../store/slices/dashboard';

const data = {
  series: [
    {
      data: [12, 24, 36, 48, 60, 72, 84],
      name: 'Due'
    },
    {
      data: [18, 36, 48, 60, 72, 84, 96],
      name: 'Overdue'
    }
  ],
  categories: [
    'Capital One',
    'Ally Bank',
    'ING',
    'Ridgewood',
    'BT Transilvania',
    'CEC',
    'CBC'
  ]
};

const PositionAgingGraph = (props) => {
  const theme = useTheme();
  const [range, setRange] = useState('Last 7 days');
  const [chartData, setChartData] = useState(data)
  const { positionClosureSummary } = useSelector((state) => state.dashboard);

  useEffect(() => {
    if (positionClosureSummary?.data?.statusWiseStats) {
      const categories = Object.keys(positionClosureSummary?.data?.statusWiseStats)
      const stats = Object.values(positionClosureSummary?.data?.statusWiseStats)
      data.series[0].data = stats
      data.categories = categories
      setChartData(data)
    }
  }, [positionClosureSummary])


  const ranges = [
    {
      label: 'Last 7 days',
      onClick: () => { setRange('Last 7 days'); }
    },
    {
      label: 'Last Month',
      onClick: () => { setRange('Last Month'); }
    },
    {
      label: 'Last Year',
      onClick: () => { setRange('Last Year'); }
    }
  ];

  const chartOptions = {
    chart: {
      background: 'transparent',
      toolbar: {
        show: false
      }
    },
    colors: ['#187B96', '#215263'],
    dataLabels: {
      enabled: false
    },
    grid: {
      borderColor: theme.palette.divider,
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      }
    },
    legend: {
      markers: {
        radius: 50
      }
    },
    states: {
      active: {
        filter: {
          type: 'none'
        }
      },
      hover: {
        filter: {
          type: 'none'
        }
      }
    },
    stroke: {
      show: false
    },
    theme: {
      mode: theme.palette.mode
    },
    tooltip: {
      theme: theme.palette.mode
    },
    xaxis: {
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      categories: data.categories,
      labels: {
        style: {
          colors: theme.palette.text.secondary
        }
      }
    },
    yaxis: {
      labels: {
        offsetX: -12,
        style: {
          colors: theme.palette.text.secondary
        }
      }
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPositionClosureSummary())
  }, [])

  return (
    <Card {...props}>
      <CardHeader
        action={(
          <ActionsMenu
            actions={ranges}
            label={range}
            size="small"
            variant="text"
          />
        )}
        title="Average aging of positions"
      />
      <Divider />
      <CardContent>
        <Chart
          height={400}
          options={chartOptions}
          series={data.series}
          type="bar"
        />
      </CardContent>
    </Card>
  );
}

export default PositionAgingGraph
