import React, { useEffect, useReducer } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Grid, Typography, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginApi, api } from '../../axios';
import LoginLayout from '../../components/LoginLayout';
import { InputField } from '../../components/InputField';
import { updateUser } from '../../store/slices/session';
import { useSnackbar } from 'notistack';
import { hasPermission } from '../../utils/permission';

const USER_INITIAL_STATE = {
  data: {
    username: '',
    password: '',
  },
  failed: false,
  loading: false,
};

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.session);
  const isSuperAdmin = hasPermission(user?.authorities, ['ROLE_SUPER_ADMINISTRATOR']);

  const [localUser, setLocalUser] = useReducer(
    (state, action) => ({
      ...state,
      ...action,
    }),
    USER_INITIAL_STATE
  );

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (user.userDBId) {
      isSuperAdmin ? navigate('/company') : navigate('/dashboard');
    }
  }, [user.userDBId, navigate]);

  const handleLogin = async (event) => {
    event.preventDefault();
    setLocalUser({ loading: true });
    try {
      const { username, password } = localUser.data;
      const data = JSON.stringify({ username, password });
      const loginResponse = await loginApi.post('/login', data);
      if (loginResponse.data) {
        localStorage.setItem('token', loginResponse.data.access_token);
        const currentUser = await api.post('/getCurrentUser');
        if (currentUser.status === 200 && currentUser.data) {
          enqueueSnackbar('Login Successful', { variant: 'success' });
          dispatch(updateUser(currentUser.data));
          isSuperAdmin ? navigate('/company') : navigate('/dashboard');
        } else {
          dispatch(updateUser(null));
          navigate('/login');
        }
      }
    } catch (error) {
      setLocalUser({ failed: true, data: { ...localUser.data, password: '' } });
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      setLocalUser({ loading: false });
    }
  };
  return (
    <>
      <LoginLayout>
        <Grid container spacing={1.5} direction='column'>
          <Grid item>
            <Typography variant='h2' color='textPrimary'>
              Hello Again!
            </Typography>
          </Grid>
          <Grid item>
            <InputField
              autoFocus
              fullWidth
              label='Username'
              name='username'
              onChange={(e) => setLocalUser({ data: { ...localUser.data, username: e.target.value } })}
              type='email'
              value={localUser.data.username}
              error={localUser.failed}
              helperText={localUser.failed && 'Invalid Username & Password'}
            />
          </Grid>
          <Grid item>
            <InputField
              fullWidth
              label='Password'
              name='password'
              onChange={(e) => setLocalUser({ data: { ...localUser.data, password: e.target.value } })}
              type='password'
              value={localUser.data.password}
              error={localUser.failed}
            />
          </Grid>
          <Grid item alignSelf={'flex-end'}>
            <Button variant='text' sx={{ color: 'secondary.main' }} onClick={() => navigate('/forgot-password')}>
              Forgot Password?
            </Button>
          </Grid>
          <Grid item>
            <LoadingButton
              loading={localUser.loading}
              variant='contained'
              color='primary'
              onClick={handleLogin}
              fullWidth
            >
              Login
            </LoadingButton>
          </Grid>
        </Grid>
        <div></div>
      </LoginLayout>
    </>
  );
};

export default Login;
